html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: white;
  touch-action: pan-x pan-y;
  overflow: hidden;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "mondifont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000028;
  touch-action: pan-x pan-y;
  overflow: hidden;
  user-select: none;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#root > div {
  flex: 1;
  display: flex;
  min-height: 0px;
  min-width: 0px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
