@import "../import.scss";

.embed-container {
  position: relative;

  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;
  width: 100%;
  height: 100%;
}

.media-container {
  margin: 12px;
  padding: 12px;
  position: relative;
  box-shadow: $lightShadow;
  background-color: white;
  border-radius: 8px;
  iframe {
  }
}

.fakeSidebar {
  position: absolute;
  right: 20px;
  bottom: 0px;
  top: 0px;
  max-width: 450px;
  z-index: 200000000;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  pointer-events: none;
  min-width: 300px;
  width: 100%;
}

.constText {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 300%;
  font-weight: bold;
}

.vidInfo {
  display: flex;
  margin-top: 12px;
  align-items: center;
  .backButton {
    display: flex;
    cursor: pointer;
  }
  .text {
    flex: 1;
    text-align: right;
    font-weight: bold;
    margin-right: 12px;
  }
}
