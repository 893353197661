@import "../../import.scss";

.uiButtons {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 0px;
  display: flex;
  pointer-events: all;
  z-index: 99999999;

  .uiButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: $lightShadow;
    padding: 12px;
    border-radius: 8px;
    margin-right: 24px;
    cursor: pointer;
    transition: background-color 0.5s;
    &:hover {
      background-color: #ddd;
    }
    &.noHover {
      position: relative;
      .popover {
        position: absolute;
        top: calc(100% + 8px);
        background-color: white;
        box-shadow: $lightShadow;
        width: 100%;
        overflow-y: auto;
        max-height: 200px;
        min-height: 50px;
        border-radius: 8px;
        .searchRes{
          margin: 12px;
          &:hover{
            opacity: 0.8;
          }
        }
      }
      input {
        outline: none;
        border: none;
      }
    }
    &.noHover:hover {
      background-color: white;
    }
  }
  @media (max-width: 1400px) {
    font-size: 80%;
    .uiButton {
      padding: 8px;
      margin-right: 12px;
    }
  }
}
