@import "../import.scss";

.boxes {
  display: flex;
  min-width: 0px;
  min-height: 0px;
}

.guideContainer {
  display: flex;
  background-color: white;
  box-shadow: $lightShadow;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 12px;
  max-width: 450px;
  .assistantImage {
    height: 70px;
    position: absolute;
    left: -70px;
    bottom: -20px;
  }
}

.guideText {
  padding: 30px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sub {
  font-weight: bold;
  font-size: 140%;
  margin-top: 34px;
  font-style: oblique;
}

.memberLogin {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: not-allowed;
  font-size: 80%;
  display: flex;
  align-items: center;
  font-weight: bold;
}
@media screen {
  .mobWarn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    z-index: 1000000001;
    .logo {
      width: 100%;
      max-width: 200px;
      min-width: 0;
    }
    .text {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
@media screen and (min-width: 800px) {
  .mobWarn {
    display: none !important;
  }
}

@media print {
  .mobWarn {
    display: none !important;
  }
}
