@import "../../import.scss";

.form {
}

.roundButton {
  height: 36px;
  border: 1px solid #f14f11;
  color: #f14f11;
  border-radius: 8px;
  line-height: 36px;
  text-align: center;
  max-width: 100px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: background-color 0.4s, color 0.4s;
  &:hover {
    color: white;
    background: #f14f11;
  }
}

.selector {
  height: 40px;
  cursor: pointer;
  outline: none;
  border: none;
  background: #ebf7f8;
  border-bottom: 1px solid #4c4c68;
  color: #4c4c68;
  width: 180px;
  margin-left: 8px;
  padding-left: 12px;
}

.input {
  border: none;
  outline: none;
  border-radius: 8px;
  border: 1px solid #f14f11;
  font-size: 120%;
  padding: 8px;
}
