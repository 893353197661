@import "../../import.scss";

.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 300000000;
  display: flex;
  align-items: center;
  justify-content: center;
  &.invis {
    .modalInner {
      background-color: transparent;
    }
  }
  .closeModalPlane {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000099;
  }
  .modalInner {
    background-color: white;
    border-radius: 8px;
    width: 50%;
    min-width: 500px;
    position: relative;
    .closeModalButton {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      cursor: pointer;
    }
  }
}
