@import "../../import.scss";

.tourViewer {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  flex-direction: column;
}

@media (min-width: $breakpoint) {
  .tourViewer {
    flex-direction: row;
  }
}

.tourIcons {
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 20px;
  bottom: 60px;
  background-color: white;
  height: 140px;
  right: 500px;
  overflow: hidden;
  box-shadow: 0px 0px 16px 0px #00009922;
  border-radius: 12px;
  .tourIcon {
    padding-right: 20px;
    padding-left: 20px;
    width: 120px;
    text-align: center;
    cursor: pointer;
    > img {
      height: 80px;
      transition: height 0.5s;
    }
    &:hover {
      > img {
        height: 100px;
      }
    }
  }
  .tourText {
    width: 250px;
    margin-right: 80px;
    .title {
      font-weight: bold;
      font-size: 120%;
      margin-bottom: 4px;
    }
  }
}
