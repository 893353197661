@import "../../import.scss";

.areaBox {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0px 0px 16px 0px #00009922;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  color: black;
  width: 230px;
  min-width: 230px;
  padding: 30px;
  padding-top: 20px;
  transition: box-shadow 0.5s;
  height: 400px;
  min-height: 400px;
  &.small {
    min-width: 200px;
    width: 300px;
    min-height: 320px;
    height: 320px;
  }
  &.hover{
    cursor: pointer;
  }
  &.hover:hover {
    box-shadow: 0px 0px 16px 0px #00009955;
  }
  .title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 100%;
    margin-bottom: 20px;
  }
  &.small .image {
    height: 120px;
    .boxImage {
      object-fit: contain;
    }
  }
  .image {
    height: 160px;
    margin-bottom: 20px;
  }
  .boxImage {
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .text {
    font-weight: 300;
    font-size: 90%;
    margin-bottom: 30px;
  }
  .chevron {
    text-align: right;
  }
  .areaSub {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 90%;
  }
}
