@import "../import.scss";

.portfolio {
  display: flex;
  justify-content: center;
  .buttons {
    display: flex;
    margin-top: 12px;
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 24px;
    .button {
      border-radius: 8px;
      box-shadow: $lightShadow;
      flex: 1;
      padding: 8px;
      padding-top: 12px;
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: inherit;
      &:hover {
        background-color: #eee;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1450px) {
  .hideOnMobile {
    display: none;
  }
  .portfolio {
    .buttons {
      padding-bottom: 120px;
    }
  }
}

.portfolioBox {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0px 0px 16px 0px #00009922;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  color: black;
  max-width: 300px;
  min-width: 170px;
  flex: 1;
  padding: 30px;
  padding-top: 20px;
  transition: box-shadow 0.5s;

  .title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 100%;
    margin-bottom: 20px;
  }
  .image {
    height: 160px;
    margin-bottom: 20px;
  }
  .boxImage {
    border-radius: 8px;
    overflow: hidden;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .text {
    font-weight: 300;
    font-size: 90%;
    margin-bottom: 30px;
  }
  .chevron {
    text-align: right;
  }
  .areaSub {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 90%;
  }
  @media screen and (max-width: 1800px) {
    .image {
      height: 90px;
    }
    .text {
      font-size: 70%;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 1350px) {
    .image {
      height: 90px;
    }
    padding: 15px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 10px;
  }
  @media screen and (max-width: 1150px) {
    .image {
      height: 60px;
    }
  }
}
