@import "../../import.scss";
.canvasWrapper {
  min-width: 0px;
  min-height: 0px;
  flex: 2;

  @media (min-width: $breakpoint) {
    flex: 1;
  }
  position: relative;
  right: 400px;
  transition: right 1s;

  .floatShadow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: red;
    width: 40px;
    pointer-events: none;
    z-index: 100000000;
    background: linear-gradient(90deg, #ffffff00 0%, #ffffff 100%);
  }
  &.floating {
    right: 0px;

    .floatShadow {
      display: none;
    }
  }
}
.factoryCanvas {
  min-width: 0px;
}
.overlay {
  pointer-events: none;
}
.tooltip {
  width: 250px;
  background-color: #ffffffdd;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 4px 1px #00000022;
  pointer-events: none;
  position: absolute;
  left: -290px;
  bottom: 100px;
  white-space: pre-wrap;
  z-index: 10000;
  @media (max-width: 1400px) {
    font-size: 80%;
    width: 200px;
    //bottom: -10px;
  }
  &.bigTooltip {
    width: 450px;
  }
  .closeTooltip {
    position: absolute;
    color: #888;
    top: 2px;
    right: 0px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    pointer-events: all;
    &:hover {
      color: black;
    }
  }
  .ttTitle {
    font-weight: bold;
    font-size: 110%;
    margin-bottom: 20px;
  }
  .nextStep {
    text-align: right;
    pointer-events: all;
  }
  .ttImage {
    overflow: hidden;
    height: 150px;
    border-radius: 8px;
    margin-bottom: 20px;
    pointer-events: all;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .steps {
    display: flex;
    justify-content: flex-end;
    .prevStep,
    .nextStep {
      width: 30px;
      padding-top: 8px;
      padding-bottom: 8px;
      pointer-events: all;

      cursor: pointer;
    }
    .disabled {
      color: #ddd;
    }
  }
}

.circleButton {
  background-color: #66666659;
  border: 4px solid #ffffffe0;
  opacity: 0.8;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translateX(-50%) translatey(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 25px;
  &.active {
    color: white;
    background-color: #ff000094;
    border: 4px solid #fff;
    width: 35px;
    height: 35px;
  }
  &.isRoom {
    width: 45px;
    height: 45px;
  }
  &.active {
    //opacity: 1;
  }
  &:hover {
    opacity: 1;
  }
}

.textModalButton {
  margin-top: 12px;
  cursor: pointer;
  pointer-events: all;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}
