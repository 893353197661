@import "../../import.scss";
.floatTourController {
  position: absolute;
  right: 0px;
  padding-right: 20px1;
  bottom: 0px;
  top: 0px;
  width: 400px;
  z-index: 200000000;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  pointer-events: none;
  min-width: 300px;
  overflow: hidden;
  font-size: 90%;

  @media (max-width: 1400px) {
    min-width: 200px;
    max-width: 250px;
    font-size: 80%;
  }
  .buttons {
    display: flex;
    pointer-events: all;
    .startTourButton {
      flex: 1;
      background: linear-gradient(#f4c472 0%, #e7b26d 100%);
      box-shadow: $lightShadow;
      padding: 8px;
      text-align: center;
      color: white;
      font-weight: bold;
      border-radius: 8px;
      cursor: pointer;
      transition: box-shadow 0.5s;
      &.blue {
        background: linear-gradient(#7098f0 0%, #5a83dc 100%);
      }
      &:hover {
        box-shadow: 0px 0px 6px 0px #999;
      }
    }
    .leftButton,
    .rightButton {
      &.disabled {
        color: #ddd;
        cursor: not-allowed;
        &:hover {
          color: #ddd;
        }
      }
      &:hover {
        color: #777;
      }
    }
    .leftButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      cursor: pointer;
    }
    .rightButton {
      cursor: pointer;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.regal {
  background-color: white;
  margin: 12px;
  border-radius: 8px;
  box-shadow: $lightShadow;
  padding: 24px;
  min-height: 0px;
  pointer-events: all;
  position: relative;
  left: calc(100% - 12px);
  .regalName {
    font-size: 80%;
    font-weight: bold;
  }
  .regalButton {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .name {
    margin-bottom: 8px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 120%;
  }
  .text {
    font-size: 90%;
  }
  .icons {
    display: flex;

    > .icon {
      flex: 1;
      overflow: hidden;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &.active {
        > img {
          width: 100%;
          height: 100%;
        }
      }
      > img {
        transition: width 0.5s, height 0.5s;
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }
  }
}
.tourController {
  min-height: 0px;
  min-width: 0px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 350px;
  width: 350px;
  flex: none;
  pointer-events: none;

  .scrollBox {
    overflow-y: auto;
    flex: 1;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ddd;
      border-radius: 3px;
    }
  }
}

.tourButtons {
  display: flex;
  font-size: 140%;
  margin: 12px;
  margin-top: 20px;
  pointer-events: all;
  .tourButton {
    margin-left: 4px;
    margin-right: 4px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    &.disabled {
      color: #bbb;
      cursor: not-allowed;
    }
  }
  .startButton {
    flex: 1;
    text-align: center;
    font-weight: bold;
    font-size: 80%;
    box-shadow: 0px 0px 16px 0px #00009922;
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    transition: box-shadow 0.5s;
    &:hover {
      box-shadow: 0px 0px 16px 0px #00009955;
    }
  }
}

.i-icon {
  background-color: #ddd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  background-color: #666;
  border: 3px solid white;
  color: white;
}
