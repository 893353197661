@import "../../import.scss";

.inner {
  flex: 1;
  margin-left: 60px;
  margin-right: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  &.noMargin {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
  }
  > .logo {
    position: absolute;
    bottom: 40px;
    left: 60px;
    height: 80px;
  }
}

