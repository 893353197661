@import "../../import.scss";

.loader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000000000;
  font-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  transition: opacity 0.5s;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  .text {
    font-size: 90%;
  }
  .logo {
    height: 150px;
  }
  .bar {
    height: 8px;
    border-radius: 4px;
    width: 100%;
    max-width: 200px;
    margin-bottom: 12px;
    background-color: #eee;
    .barInner {
      border-radius: 4px;
      height: 8px;
      background-color: $orange;
    }
  }
  .version {
    position: absolute;
    bottom: 24px;
    left: 24px;
    font-size: 90%;
  }
}
