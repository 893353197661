@import "../../import.scss";

.infoBox {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: $lightShadow;
  margin: 12px;
  overflow-y: auto;
  min-height: 0px;
  pointer-events: all;
  position: relative;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 3px;
  }
  .readmore {
    font-weight: bold;
  }
  .label {
    font-size: 90%;
  }
  .name {
    margin-bottom: 8px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 120%;
  }
  .text {
    font-size: 90%;
    white-space: pre-wrap;
  }
  .image {
    height: 150px;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 12px;
    .img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .subb {
    font-weight: bold;
  }
  .closeButton {
    position: absolute;
    top: 25px;
    right: 20px;
    color: black;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.factsheetlink {
  text-decoration: underline;
  margin-top: 12px;
  cursor: pointer;
}
